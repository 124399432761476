//获取一个日期所在月的所有天
export function getMonthDays(dateStr) {
    const date = new Date(dateStr)
    let year = date.getFullYear()  // 获取年份
    let month = date.getMonth() + 1  // 获取月份（注意月份要加1）
    let daysInMonth = new Date(year, month, 0).getDate()  // 获取当月天数
    let days = []
    for (let i = 1; i <= daysInMonth; i++) {
        days.push(i)
    }
    return days
}

// 获取一个日期所在星期的所有天
export function getWeekDays(dateStr) {
    const date = new Date(dateStr)
    let days = [];
    let day = date.getDay(); // 获取当前星期的索引，0 表示星期天，1 表示星期一
    // 获取当前星期的第一天（星期一）
    let firstDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - day + 1);
    // 获取当前星期的所有天
    for (let i = 0; i < 7; i++) {
        let currentDate = new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate() + i);
        days.push(`${formatNumber(currentDate.getMonth()+1)}-${formatNumber(currentDate.getDate())}`);
    }
    return days;
}
// 补0函数
function formatNumber(n) {
    n = n.toString();
    return n[1] ? n : '0' + n;
}

// KB转GB
export function convertKBToGB(kilobytes) {
    var gigabytes = kilobytes / 1024 / 1024;
    return gigabytes.toFixed(2);
}

// 获取一个日期的天
export function getDayOfMonth(dateString) {
    const date = new Date(dateString);
    return date.getDate();
}

//将秒数转换为时分秒的格式
export function formatTime(seconds) {
    let hours = parseInt(Math.floor(seconds / 3600));
    let minutes = parseInt(Math.floor((seconds - (hours * 3600)) / 60));
    let remainingSeconds = parseInt(seconds - (hours * 3600) - (minutes * 60));
    // 使用三元表达式确保格式的正确性
    let hoursStr = (hours < 10) ? "0" + hours : hours;
    let minutesStr = (minutes < 10) ? "0" + minutes : minutes;
    let secondsStr = (remainingSeconds < 10) ? "0" + remainingSeconds : remainingSeconds;
    return `${hoursStr}:${minutesStr}:${secondsStr}`;
}

